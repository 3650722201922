@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800;900&display=swap');

.popup-wrapper {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: #25252880;

    display: 'flex';
    justify-content:'center';
    align-items:'center';
}

.popup-box {
    background-color: #070708;

    width: 514px;

    margin: 10% auto 10% auto;
    padding: 32px;
}

@media(max-width: 999px) {
    .popup-box {
        width: 100%;
    
        margin: 20% auto auto auto;
    } 
}

.popup-header {
    display: flex;
    justify-content: space-between;
}

.popup-header-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-align: left;
    line-height: 30px;
}

.popup-header-close-icon-section {
    text-align: right;
}

.popup-header-close-icon {
    width: 24px;
    height: 24px;
}

.popup-header-close-icon:hover {
    cursor: pointer;
}

.popup-info {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    text-align: left;
    line-height: 24px;
    margin: 32px 0;
}

.popup-input-section {
    margin: 32px 0 16px 0;
}

.popup-input-info {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #999;
    text-align: left;
    line-height: 24px;
    margin: 0 0 16px 0;
}

.popup-input {
    border: 1px solid #17171C;
    padding: 8px;
    display: flex;
    justify-content: space-between;
}

.popup-input-max-amount-bullet {
    height: 32px;
    border-radius: 999px;
    background-color: #2E2E38;
    padding: 4px 8px;
}

@media(max-width: 999px) {
    .popup-input-max-amount-bullet {
        width: 190px;
        position: absolute;
        float: right;
        right: 10%;
        margin: 48px 0;
    }

    .popup-terms-toggle-section {
        margin-top: 64px;
    }
}

.popup-input-max-amount-bullet:hover {
    cursor: pointer;
    opacity: 0.9;
}

.popup-input-max-amount {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #999;
    text-align: center;
    padding: 2px 0 0 0;
}

.popup-button {
    width: 100%;
    height: 56px;
    padding: 16px 0;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
    margin: 16px 0;
}

@media(max-width:999px) {
    .popup-button.withdraw, .popup-button.deposit {
        margin-top: 64px;
    }
}

.popup-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.popup-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.popup-terms-toggle-section {
    width: 100%;
    display: flex;
    justify-content: left;
}

.popup-terms-toggle-checkbox-icon {
    width: 24px;
    height: 24px;
}

.popup-terms-toggle-checkbox-icon:hover {
    cursor: pointer;
}

.popup-terms-info {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
    text-align: left;
    margin: 0 0 0 8px;
}

.popup-terms-link {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    background: linear-gradient(90deg,#e926c3 10%,#ff4d86);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 24px;
    text-align: left;
    margin: 0 0 0 4px;
}

.popup-terms-alert {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #FD0000;
    line-height: 16px;
    text-align: left;
}

.popup-get-rewards-box {
    width: 100%;
    height: 76px;
    padding: 16px;
    background-color: #17171C;
    display: flex;
    justify-content: space-between;
    margin: 32px 0 0 0;
}

.popup-get-rewards-balance-section {
    display: flex;
    justify-content: left;
}

.popup-get-rewards-token-icon {
    width: 44px;
    height: 44px;
}

.popup-get-rewards-balance {
    margin: 0 0 0 16px;
}

.popup-get-rewards-balance-info {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
    text-align: left;
}

.popup-get-rewards-balance-value {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #999;
    line-height: 20px;
    text-align: left;
}

.popup-get-rewards-button {
    width: 136px;
    height: 40px;
    padding: 8px 12px;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
}

.popup-get-rewards-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.popup-get-rewards-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.popup-wrapper-amount-alert {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #FD0000;
    line-height: 16px;
    text-align: left;
}

@media(max-width: 999px) {
    .popup-get-rewards-button {
        width: 80px;
        height: 60px;
    }

    .popup-get-rewards-box {
        height: 90px;
    }

    .popup-get-rewards-balance-section {
        margin: 8px 0 0 0;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.w-products-wrapper {
    width: 100%;
    background: linear-gradient(180deg, #17171C 0%, #020202 100%);
    padding: 80px 0 60px 0;
}

.w-products-content {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.w-product-card {
    width: 32%;
    background-color: #020202;
    padding: 32px;
}

.w-product-card-logo-section {
    text-align: center;
}

.w-product-card-logo {
    height: auto;
    width: 95%;
    margin: auto;
}

.w-product-card-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #999;
    text-align: left;
    line-height: 30px;
    margin: 36px 0;
}

@media(min-width: 1000px) {
    .w-product-card-text {
        height: 280px;
    }
}

@media(min-width: 1200px) {
    .w-product-card-text {
        height: 260px;
    }
}

@media(min-width: 1200px) {
    .w-product-card-text {
        height: 240px;
    }
}

@media(min-width: 1300px) {
    .w-product-card-text {
        height: 220px;
    }
}

@media(min-width: 1400px) {
    .w-product-card-text {
        height: 200px;
    }
}

@media(min-width: 1500px) {
    .w-product-card-text {
        height: 160px;
    }
}

.w-product-card-text-link-section {
    display: flex;
    justify-content: center;
}

.w-product-card-text-link-section:hover span {display:none}
.w-product-card-text-link-section:hover:before {content:"Coming soon"}

.w-product-card-text-link-section:before {
    font-family: 'DM Sans', sans-serif;
    color: #999;
}
.w-product-card-text-link-section:hover .w-product-card-link-icon {
    display: none;
}

.w-product-card-text-link {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    background: linear-gradient(90deg,#e926c3 10%,#ff4d86);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.w-product-card-link-icon {
    width: 16px;
    height: 16px;
    margin: 4px 0 0 4px;
}

/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */

.m-products-wrapper {
    width: 100%;
    background: linear-gradient(180deg, #070708 0%, #020202 100%);
    padding: 16px 0 120px 0;
    margin: 0 0 148px 0;
}

.m-products-content {
    width: 90%;
    margin: auto;
}

.m-product-card {
    width: 100%;
    background-color: #020202;
    padding: 32px;
    margin: 0 0 16px 0;
}

.m-product-card-logo-section {
    text-align: center;
}

.m-product-card-logo {
    height: auto;
    width: 95%;
    margin: auto;
}

.m-product-card-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #999;
    text-align: left;
    line-height: 30px;
    margin: 36px 0;
}

.m-product-card-text-link-section {
    display: flex;
    justify-content: center;
}

.m-product-card-text-link-section:hover span {display:none}
.m-product-card-text-link-section:hover:before {content:"Coming soon"}

.m-product-card-text-link-section:before {
    font-family: 'DM Sans', sans-serif;
    color: #999;
}
.m-product-card-text-link-section:hover .m-product-card-link-icon {
    display: none;
}

.m-product-card-text-link {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    background: linear-gradient(90deg,#e926c3 10%,#ff4d86);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.m-product-card-link-icon {
    width: 16px;
    height: 16px;
    margin: 4px 0 0 4px;
}
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.w-cards-wrapper {
    width: 100%;
    padding: 80px 0;
    background-color: #020202;
}

.w-cards-content {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.wm-card {
    width: 32%;
    background-color: #070708;
    padding: 18px 20px;
}

.card-content {
    display: flex;
    justify-content: left;
}

.card-icon-section {
    text-align: left;
}

.card-icon {
    width: 58px;
    height: 58px;
}

.card-info-section {
    padding: 0 0 0 18px;
}

.card-info-primary-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    text-align: left;
    line-height: 38px;
}

.card-info-secondary-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #999;
    text-align: left;
    line-height: 20px;
}

/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */

@media(max-width: 999px) {
    .wm-card {
        width: 90%;
        margin: 0 auto 16px auto;
        background-color: #070708;
    }
}

.m-cards-wrapper {
    background-color: #020202;
    width: 100%;
    padding: 32px 0;
}
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

/* WEB */
/* WEB */
/* WEB */
/* WEB */
/* WEB */

.w-header-wrapper {
    width: 100%;
    height: 80px;
    background-color: #070708;

    /* sticky */
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
}

.w-header-content {
    width: 90%;
    margin: auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
}

.w-header-logo-section {
    text-align: left;
}

.w-header-logo {
    width: auto;
    height: 40px;
}

.w-header-connect-wallet-button {
    width: 173px;
    height: 48px;
    padding: 12px 20px;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
}

.w-header-connect-wallet-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.w-header-connect-wallet-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.w-header-wallet-connected-section {
    display: flex;
    justify-content: space-between;
}

.w-header-address-box {
    width: 225px;
    height: 48px;
    padding: 8px 16px;
    background-color: #17171C;
    display: flex;
    justify-content: space-between;
}

.w-header-address-box-balance-bullet {
    width: 82px;
    height: 32px;
    border-radius: 999px;
    background-color: #070708;
    padding: 4px 0;
}

.w-header-address-box-balance-bullet-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    text-align: center;
}

.w-header-address-box-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding: 4px 0 0 0;
}

.w-header-log-out-button {
    width: 100px;
    height: 48px;
    padding: 8px 16px;
    margin: 0 0 0 8px;
    background-color: #17171C;
}

.w-header-log-out-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.w-header-log-out-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding: 4px 0 0 0;
}

/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */

.m-header-wrapper {
    width: 100%;
    height: 90px;
    background-color: #020202;

    /* sticky */
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
}

.m-header-content {
    width: 90%;
    margin: auto;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
}

.m-header-logo-section {
    text-align: left;
    margin: 10px 0 0 0;
}

.m-header-logo {
    height: 32px;
    width: auto;
}

.m-header-wallet-section {
    margin: 16px 0 0 0;
}

.m-header-connect-wallet-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    background: linear-gradient(90deg,#e926c3 10%,#ff4d86);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 24px;
    text-align: right;
    padding: 4px 0 0 0;
}

.m-header-wallet-connected-section {
    display: flex;
    justify-content: space-between;
}

.m-header-address-box {
    width: 120px;
    height: 60px;
    padding: 4px 8px;
    background-color: #17171C;
}

.m-header-address-box-balance-bullet {
    width: 82px;
    margin: auto;
    height: 32px;
    border-radius: 999px;
    background-color: #070708;
    padding: 4px 0;
}

.m-header-address-box-balance-bullet-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    text-align: center;
}

.m-header-address-box-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #999;
    text-align: center;
}

.m-header-log-out-button {
    width: 85px;
    height: 60px;
    padding: 4px 8px;
    background-color: #17171C;
    margin: 0 0 0 8px;
}

.m-header-log-out-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.m-header-log-out-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding: 16px 0 0 0;
}

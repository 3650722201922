.w-footer-wrapper {
    padding: 0 0 10px 0;
    background-color: #020202;
}

.w-footer-content {
    width: 80%;
    margin: auto;
    padding: 60px 0;
    border-top: 1px solid #2E2E38;
    border-bottom: 1px solid #2E2E38;
    display: flex;
    justify-content: space-between;
}

.w-footer-logo-section {
    width: 50%;
    text-align: left;
    /* display: flex;
    align-items: center; */
}

.w-footer-logo {
    width: 200px;
    height: auto;
}

.w-footer-motto {
    padding: 10px 0 0 0;
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    text-align: left;
}

.w-footer-menu-section {
    width: 50%;
    display: flex;
}

.w-footer-menu-col {
    width: 30%;
    text-align: left;
}

.w-footer-menu-title {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #999;
    padding: 0 0 20px 0;
}

.w-footer-menu-item {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
}

.w-footer-menu-item:hover {
    cursor: pointer;
    color: #E926C3;
}

.w-footer-menu-item.launch:hover span {display:none}
.w-footer-menu-item.launch:hover:before {content:"Coming soon"}

/* ALL RIGHTS RESERVED */
.w-arr-wrapper {
    background-color: #020202;
    padding: 10px 0 20px 0;
}

.w-arr-content {
    width: 80%;
    margin: auto;
   display: flex;
   justify-content: space-between;
}

.w-arr-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #999;
    width: 50%;
}

.w-arr-socials-section {
    width: 50%;
    display: flex;
    justify-content: right;
}

.w-arr-social-icon {
    width: 24px;
    height: 24px; 
    margin: 0 12px 0;
}

.w-arr-social-icon:hover {
    cursor: pointer;
    opacity: 0.9;
}

.w-arr-social-icon.last {
    margin: 0 0 0 12px;
}

.w-arr-social-icon.first {
    margin: 0 12px 0 0;
}
@import url(https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
/* WEB */
/* WEB */
/* WEB */
/* WEB */
/* WEB */

.w-header-wrapper {
    width: 100%;
    height: 80px;
    background-color: #070708;

    /* sticky */
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
}

.w-header-content {
    width: 90%;
    margin: auto;
    padding: 20px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.w-header-logo-section {
    text-align: left;
}

.w-header-logo {
    width: auto;
    height: 40px;
}

.w-header-connect-wallet-button {
    width: 173px;
    height: 48px;
    padding: 12px 20px;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
}

.w-header-connect-wallet-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.w-header-connect-wallet-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.w-header-wallet-connected-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.w-header-address-box {
    width: 225px;
    height: 48px;
    padding: 8px 16px;
    background-color: #17171C;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.w-header-address-box-balance-bullet {
    width: 82px;
    height: 32px;
    border-radius: 999px;
    background-color: #070708;
    padding: 4px 0;
}

.w-header-address-box-balance-bullet-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    text-align: center;
}

.w-header-address-box-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding: 4px 0 0 0;
}

.w-header-log-out-button {
    width: 100px;
    height: 48px;
    padding: 8px 16px;
    margin: 0 0 0 8px;
    background-color: #17171C;
}

.w-header-log-out-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.w-header-log-out-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding: 4px 0 0 0;
}

/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */

.m-header-wrapper {
    width: 100%;
    height: 90px;
    background-color: #020202;

    /* sticky */
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
}

.m-header-content {
    width: 90%;
    margin: auto;
    padding: 16px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.m-header-logo-section {
    text-align: left;
    margin: 10px 0 0 0;
}

.m-header-logo {
    height: 32px;
    width: auto;
}

.m-header-wallet-section {
    margin: 16px 0 0 0;
}

.m-header-connect-wallet-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    background: linear-gradient(90deg,#e926c3 10%,#ff4d86);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 24px;
    text-align: right;
    padding: 4px 0 0 0;
}

.m-header-wallet-connected-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.m-header-address-box {
    width: 120px;
    height: 60px;
    padding: 4px 8px;
    background-color: #17171C;
}

.m-header-address-box-balance-bullet {
    width: 82px;
    margin: auto;
    height: 32px;
    border-radius: 999px;
    background-color: #070708;
    padding: 4px 0;
}

.m-header-address-box-balance-bullet-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    text-align: center;
}

.m-header-address-box-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #999;
    text-align: center;
}

.m-header-log-out-button {
    width: 85px;
    height: 60px;
    padding: 4px 8px;
    background-color: #17171C;
    margin: 0 0 0 8px;
}

.m-header-log-out-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.m-header-log-out-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding: 16px 0 0 0;
}

.w-cards-wrapper {
    width: 100%;
    padding: 80px 0;
    background-color: #020202;
}

.w-cards-content {
    width: 90%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.wm-card {
    width: 32%;
    background-color: #070708;
    padding: 18px 20px;
}

.card-content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
}

.card-icon-section {
    text-align: left;
}

.card-icon {
    width: 58px;
    height: 58px;
}

.card-info-section {
    padding: 0 0 0 18px;
}

.card-info-primary-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    text-align: left;
    line-height: 38px;
}

.card-info-secondary-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #999;
    text-align: left;
    line-height: 20px;
}

/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */

@media(max-width: 999px) {
    .wm-card {
        width: 90%;
        margin: 0 auto 16px auto;
        background-color: #070708;
    }
}

.m-cards-wrapper {
    background-color: #020202;
    width: 100%;
    padding: 32px 0;
}
.w-products-wrapper {
    width: 100%;
    background: linear-gradient(180deg, #17171C 0%, #020202 100%);
    padding: 80px 0 60px 0;
}

.w-products-content {
    width: 90%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.w-product-card {
    width: 32%;
    background-color: #020202;
    padding: 32px;
}

.w-product-card-logo-section {
    text-align: center;
}

.w-product-card-logo {
    height: auto;
    width: 95%;
    margin: auto;
}

.w-product-card-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #999;
    text-align: left;
    line-height: 30px;
    margin: 36px 0;
}

@media(min-width: 1000px) {
    .w-product-card-text {
        height: 280px;
    }
}

@media(min-width: 1200px) {
    .w-product-card-text {
        height: 260px;
    }
}

@media(min-width: 1200px) {
    .w-product-card-text {
        height: 240px;
    }
}

@media(min-width: 1300px) {
    .w-product-card-text {
        height: 220px;
    }
}

@media(min-width: 1400px) {
    .w-product-card-text {
        height: 200px;
    }
}

@media(min-width: 1500px) {
    .w-product-card-text {
        height: 160px;
    }
}

.w-product-card-text-link-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.w-product-card-text-link-section:hover span {display:none}
.w-product-card-text-link-section:hover:before {content:"Coming soon"}

.w-product-card-text-link-section:before {
    font-family: 'DM Sans', sans-serif;
    color: #999;
}
.w-product-card-text-link-section:hover .w-product-card-link-icon {
    display: none;
}

.w-product-card-text-link {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    background: linear-gradient(90deg,#e926c3 10%,#ff4d86);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.w-product-card-link-icon {
    width: 16px;
    height: 16px;
    margin: 4px 0 0 4px;
}

/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */

.m-products-wrapper {
    width: 100%;
    background: linear-gradient(180deg, #070708 0%, #020202 100%);
    padding: 16px 0 120px 0;
    margin: 0 0 148px 0;
}

.m-products-content {
    width: 90%;
    margin: auto;
}

.m-product-card {
    width: 100%;
    background-color: #020202;
    padding: 32px;
    margin: 0 0 16px 0;
}

.m-product-card-logo-section {
    text-align: center;
}

.m-product-card-logo {
    height: auto;
    width: 95%;
    margin: auto;
}

.m-product-card-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #999;
    text-align: left;
    line-height: 30px;
    margin: 36px 0;
}

.m-product-card-text-link-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.m-product-card-text-link-section:hover span {display:none}
.m-product-card-text-link-section:hover:before {content:"Coming soon"}

.m-product-card-text-link-section:before {
    font-family: 'DM Sans', sans-serif;
    color: #999;
}
.m-product-card-text-link-section:hover .m-product-card-link-icon {
    display: none;
}

.m-product-card-text-link {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    background: linear-gradient(90deg,#e926c3 10%,#ff4d86);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.m-product-card-link-icon {
    width: 16px;
    height: 16px;
    margin: 4px 0 0 4px;
}
.w-footer-wrapper {
    padding: 0 0 10px 0;
    background-color: #020202;
}

.w-footer-content {
    width: 80%;
    margin: auto;
    padding: 60px 0;
    border-top: 1px solid #2E2E38;
    border-bottom: 1px solid #2E2E38;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.w-footer-logo-section {
    width: 50%;
    text-align: left;
    /* display: flex;
    align-items: center; */
}

.w-footer-logo {
    width: 200px;
    height: auto;
}

.w-footer-motto {
    padding: 10px 0 0 0;
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    text-align: left;
}

.w-footer-menu-section {
    width: 50%;
    display: -webkit-flex;
    display: flex;
}

.w-footer-menu-col {
    width: 30%;
    text-align: left;
}

.w-footer-menu-title {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #999;
    padding: 0 0 20px 0;
}

.w-footer-menu-item {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
}

.w-footer-menu-item:hover {
    cursor: pointer;
    color: #E926C3;
}

.w-footer-menu-item.launch:hover span {display:none}
.w-footer-menu-item.launch:hover:before {content:"Coming soon"}

/* ALL RIGHTS RESERVED */
.w-arr-wrapper {
    background-color: #020202;
    padding: 10px 0 20px 0;
}

.w-arr-content {
    width: 80%;
    margin: auto;
   display: -webkit-flex;
   display: flex;
   -webkit-justify-content: space-between;
           justify-content: space-between;
}

.w-arr-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #999;
    width: 50%;
}

.w-arr-socials-section {
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
}

.w-arr-social-icon {
    width: 24px;
    height: 24px; 
    margin: 0 12px 0;
}

.w-arr-social-icon:hover {
    cursor: pointer;
    opacity: 0.9;
}

.w-arr-social-icon.last {
    margin: 0 0 0 12px;
}

.w-arr-social-icon.first {
    margin: 0 12px 0 0;
}
.popup-wrapper {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: #25252880;

    display: 'flex';
    -webkit-justify-content:'center';
            justify-content:'center';
    -webkit-align-items:'center';
            align-items:'center';
}

.popup-box {
    background-color: #070708;

    width: 514px;

    margin: 10% auto 10% auto;
    padding: 32px;
}

@media(max-width: 999px) {
    .popup-box {
        width: 100%;
    
        margin: 20% auto auto auto;
    } 
}

.popup-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.popup-header-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-align: left;
    line-height: 30px;
}

.popup-header-close-icon-section {
    text-align: right;
}

.popup-header-close-icon {
    width: 24px;
    height: 24px;
}

.popup-header-close-icon:hover {
    cursor: pointer;
}

.popup-info {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    text-align: left;
    line-height: 24px;
    margin: 32px 0;
}

.popup-input-section {
    margin: 32px 0 16px 0;
}

.popup-input-info {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #999;
    text-align: left;
    line-height: 24px;
    margin: 0 0 16px 0;
}

.popup-input {
    border: 1px solid #17171C;
    padding: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.popup-input-max-amount-bullet {
    height: 32px;
    border-radius: 999px;
    background-color: #2E2E38;
    padding: 4px 8px;
}

@media(max-width: 999px) {
    .popup-input-max-amount-bullet {
        width: 190px;
        position: absolute;
        float: right;
        right: 10%;
        margin: 48px 0;
    }

    .popup-terms-toggle-section {
        margin-top: 64px;
    }
}

.popup-input-max-amount-bullet:hover {
    cursor: pointer;
    opacity: 0.9;
}

.popup-input-max-amount {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #999;
    text-align: center;
    padding: 2px 0 0 0;
}

.popup-button {
    width: 100%;
    height: 56px;
    padding: 16px 0;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
    margin: 16px 0;
}

@media(max-width:999px) {
    .popup-button.withdraw, .popup-button.deposit {
        margin-top: 64px;
    }
}

.popup-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.popup-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.popup-terms-toggle-section {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
}

.popup-terms-toggle-checkbox-icon {
    width: 24px;
    height: 24px;
}

.popup-terms-toggle-checkbox-icon:hover {
    cursor: pointer;
}

.popup-terms-info {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
    text-align: left;
    margin: 0 0 0 8px;
}

.popup-terms-link {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    background: linear-gradient(90deg,#e926c3 10%,#ff4d86);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 24px;
    text-align: left;
    margin: 0 0 0 4px;
}

.popup-terms-alert {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #FD0000;
    line-height: 16px;
    text-align: left;
}

.popup-get-rewards-box {
    width: 100%;
    height: 76px;
    padding: 16px;
    background-color: #17171C;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 32px 0 0 0;
}

.popup-get-rewards-balance-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
}

.popup-get-rewards-token-icon {
    width: 44px;
    height: 44px;
}

.popup-get-rewards-balance {
    margin: 0 0 0 16px;
}

.popup-get-rewards-balance-info {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
    text-align: left;
}

.popup-get-rewards-balance-value {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #999;
    line-height: 20px;
    text-align: left;
}

.popup-get-rewards-button {
    width: 136px;
    height: 40px;
    padding: 8px 12px;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
}

.popup-get-rewards-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.popup-get-rewards-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.popup-wrapper-amount-alert {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #FD0000;
    line-height: 16px;
    text-align: left;
}

@media(max-width: 999px) {
    .popup-get-rewards-button {
        width: 80px;
        height: 60px;
    }

    .popup-get-rewards-box {
        height: 90px;
    }

    .popup-get-rewards-balance-section {
        margin: 8px 0 0 0;
    }
}
/* WEB */
/* WEB */
/* WEB */
/* WEB */
/* WEB */

.w-wrapper {
    background-color: #020202;
    width: 100%;
    min-height: 100vh;
}

.w-buttons-wrapper {
    width: 100%;
    background-color: #020202;
    padding: 160px 0 80px;
}

.w-buttons-content {
    width: 90%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.w-buttons-header {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 42px;
    font-weight: 600;
    color: #fff;
    text-align: left;
    line-height: 56px;
}

.w-buttons-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
}

.w-deposit-button {
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
    padding: 16px 20px;
}

.w-deposit-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.w-withdraw-button {
    background-color: #17171C;
    padding: 16px 20px;
    margin: 0 16px;
}

.w-withdraw-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}

.w-get-rewards-button {
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
    padding: 16px 20px;
}

.w-get-rewards-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.w-deposit-button:hover, .w-withdraw-button:hover, .w-get-rewards-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */

.m-wrapper {
    background-color: #020202;
    width: 100%;
    min-height: 100vh;
}

.m-button-wrapper {
    width: 100%;
    background-color: #020202;
    padding: 120px 0 32px 0;
}

.m-button-content {
    width: 90%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.m-button-header {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: #fff;
}

.m-claim-reward-button {
    width: 168px;
    height: 40px;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
    padding: 8px 0;
}

.m-claim-reward-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: #020202;
}

.m-buttons-wrapper {
    width: 100%;
    background-color: #070708;
    padding: 36px 0 64px 0;

    /* sticky */
    position: fixed;
    z-index: 1;
    bottom: 0;
    overflow-x: hidden;
}

.m-buttons-content {
    width: 90%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.m-deposit-button {
    width: 48%;
    height: 48px;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
    padding: 12px 0;
}

.m-deposit-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.m-withdraw-button {
    width: 48%;
    height: 48px;
    background-color: #17171C;
    padding: 12px 0;
}

.m-withdraw-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}

.w-loading-wrapper {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #25252880;
}

.w-loading-content {
    text-align: center;
    margin: 20% auto auto auto;
}
.bn-onboard-custom .bn-onboard-modal-content {
    background-color: #070708 !important;
    color: white;
}

.bn-onboard-custom .bn-onboard-icon-button:hover {
    background-color: rgba(244,60,163,0.2) !important;
}

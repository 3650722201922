@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');


/* WEB */
/* WEB */
/* WEB */
/* WEB */
/* WEB */

.w-wrapper {
    background-color: #020202;
    width: 100%;
    min-height: 100vh;
}

.w-buttons-wrapper {
    width: 100%;
    background-color: #020202;
    padding: 160px 0 80px;
}

.w-buttons-content {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.w-buttons-header {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 42px;
    font-weight: 600;
    color: #fff;
    text-align: left;
    line-height: 56px;
}

.w-buttons-section {
    display: flex;
    justify-content: right;
}

.w-deposit-button {
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
    padding: 16px 20px;
}

.w-deposit-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.w-withdraw-button {
    background-color: #17171C;
    padding: 16px 20px;
    margin: 0 16px;
}

.w-withdraw-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}

.w-get-rewards-button {
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
    padding: 16px 20px;
}

.w-get-rewards-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.w-deposit-button:hover, .w-withdraw-button:hover, .w-get-rewards-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */
/* MOBILE */

.m-wrapper {
    background-color: #020202;
    width: 100%;
    min-height: 100vh;
}

.m-button-wrapper {
    width: 100%;
    background-color: #020202;
    padding: 120px 0 32px 0;
}

.m-button-content {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.m-button-header {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: #fff;
}

.m-claim-reward-button {
    width: 168px;
    height: 40px;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
    padding: 8px 0;
}

.m-claim-reward-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: #020202;
}

.m-buttons-wrapper {
    width: 100%;
    background-color: #070708;
    padding: 36px 0 64px 0;

    /* sticky */
    position: fixed;
    z-index: 1;
    bottom: 0;
    overflow-x: hidden;
}

.m-buttons-content {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.m-deposit-button {
    width: 48%;
    height: 48px;
    background: linear-gradient(90deg, #E926C3 0%, #FF4D86 97.5%);
    padding: 12px 0;
}

.m-deposit-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #020202;
    text-align: center;
}

.m-withdraw-button {
    width: 48%;
    height: 48px;
    background-color: #17171C;
    padding: 12px 0;
}

.m-withdraw-button-text {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}

.w-loading-wrapper {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #25252880;
}

.w-loading-content {
    text-align: center;
    margin: 20% auto auto auto;
}